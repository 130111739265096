import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@kubrick/ui/components/ui/avatar";
import {
  BriefcaseIcon,
  Building,
  Gauge,
  LinkIcon,
  RadioTowerIcon,
  ScanEyeIcon,
  Settings,
  User,
} from "@kubrick/ui/components/ui/icons";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@kubrick/ui/components/ui/sidebar";
import { NavLink, useRouteLoaderData } from "react-router";

import { Skeleton } from "@kubrick/ui/components/ui/skeleton";
import { Route } from "../../+types/root";

const links = [
  {
    href: "/",
    label: "Advertising",
    icon: Gauge,
    subItems: [
      {
        href: "/campaigns",
        label: "Campaigns",
        icon: BriefcaseIcon,
      },
      {
        href: "/performance",
        label: "Performance",
        icon: Gauge,
      },
    ],
  },
  {
    href: "/manager",
    label: "Manager",
    protected: true,
    subItems: [
      // {
      //   href: "/manager/campaigns",
      //   label: "Campaign Go Lives",
      //   icon: RadioTowerIcon,
      // },
      {
        href: "/manager/business_review",
        label: "Business Review",
        icon: ScanEyeIcon,
      },
    ],
  },
  {
    href: "/settings",
    label: "Settings",
    icon: Settings,
    subItems: [
      {
        href: "/settings/business",
        label: "Business Profile",
        icon: Building,
      },
      {
        href: "/settings/integrations",
        label: "Integrations",
        icon: LinkIcon,
      },
    ],
  },
];

export const SidebarApp = () => {
  const data = useRouteLoaderData<Route.ComponentProps["loaderData"]>("root");
  const isCampaignManager = data?.isCampaignManager;

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <NavLink to="/" className="flex items-center gap-2">
              {({ isActive }) => (
                <SidebarMenuButton
                  asChild
                  size="lg"
                  isActive={isActive}
                  className="transition-[radius] ease-linear group-data-[collapsible=icon]:rounded-none"
                >
                  <div className="flex items-center gap-2">
                    <div className="flex aspect-square size-8 items-center justify-center text-primary-foreground">
                      <Avatar className="size-8 rounded-none">
                        <AvatarImage src="/dgm_logo_navy.png" alt="The Guru" />
                        <AvatarFallback className="rounded-none">
                          <Skeleton className="size-8" />
                        </AvatarFallback>
                      </Avatar>
                    </div>
                    <span className="text-lg font-bold">the guru</span>
                  </div>
                </SidebarMenuButton>
              )}
            </NavLink>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        {links.map((link) => {
          if (link.protected && !isCampaignManager) return null;
          return (
            <SidebarGroup key={link.href}>
              <SidebarGroupLabel>{link.label}</SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {link.subItems.map((subItem) => (
                    <SidebarMenuItem key={subItem.href}>
                      <NavLink to={subItem.href} prefetch="intent">
                        {({ isActive }) => (
                          <SidebarMenuButton
                            asChild
                            isActive={isActive}
                            tooltip={subItem.label}
                          >
                            <div className="flex items-center gap-2">
                              <subItem.icon className="h-4 w-4" />
                              <span>{subItem.label}</span>
                            </div>
                          </SidebarMenuButton>
                        )}
                      </NavLink>
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          );
        })}
      </SidebarContent>
      <SidebarFooter className="mt-auto">
        <SidebarMenu>
          <SidebarMenuItem>
            <NavLink to="/profile">
              {({ isActive }) => (
                <SidebarMenuButton isActive={isActive}>
                  <User className="h-4 w-4" />
                  <span>Profile</span>
                </SidebarMenuButton>
              )}
            </NavLink>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarFooter>
    </Sidebar>
  );
};
