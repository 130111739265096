import { LoaderFunctionArgs } from "react-router";

import { redirect } from "react-router";
import { getUserSession } from "../../.server/auth";

import { SignIn as ClerkSignIn } from "@clerk/react-router";

export async function loader(args: LoaderFunctionArgs) {
  const { userId } = await getUserSession(args);

  // Redirect to home if user is already logged in
  if (userId) {
    throw redirect("/");
  }

  return null;
}

export default function Login() {
  return <ClerkSignIn />;
}
